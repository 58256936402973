import PaginationRequest from "@/models/general/PaginationRequest";
import GeneralFiltersTable from "@/models/general/Paginations/Filters/GeneralFiltersTable";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from '../../../../commons/MutationsTable';
import store from '@/store';
import Modules from "@/store/enums/Modules";
import ClientRow from "@/models/clients/ClientRow";
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import { Actions } from "@/store/commons/ActionsTable";
import TablePaginationRequest from "@/models/general/TablePaginationRequest";
import TableSaleQuoteState from "../../models/TableSaleQuoteState";
import SaleRow from "@/models/sales/SaleRows";
import SaleService from "@/core/services/sales/SaleService";
import SalesTableService from "@/core/services/sales/interfaces/SalesTableService";
import FilterSales from "@/models/sales/FilterSales";

@Module({dynamic: true, store, namespaced: true, name: Modules.SaleTableQuoteModule})
export default class TableSaleQuoteModule extends VuexModule implements TableSaleQuoteState {

    records = [] as SaleRow[];
    totalRecords = 0;
    filters = {
        invoice: ''
        , rfc: ''
        , status: '0'
    } as FilterSales;
    pagination = {
        currentPage: 1
        , pageSize: 10 //default
    } as PaginationRequest;
    loading = false;

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: SaleRow[]){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: unknown){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters);

    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

    /**
     * Se obtienen los datos de la tabla de clientes mediante la paginación
     * @param paginator paginador de las notas
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        
         const resp = (await service.getTableQuote(this.pagination, this.filters)
         .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ??
             {
                 data: [] as SaleRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
             }
 
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
    }

    /**
       * Realiza un busqueda con la nueva paginación
       * @param pagination paginación de la tabla
       */
     @Action
    async [Actions.CHANGE_PAGE](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION, pagination);
          this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    /**
     * Filtros que se aplican y despues se hace la busqueda de los datos
     * @param filters filtros nuevos
     */
    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.commit(Mutations.SET_PAGINATION, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
      });
        this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
}

/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
 const service =  new SaleService() as SalesTableService;