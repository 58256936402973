
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import {computed, defineComponent, ref, watch } from "vue"
import { hideModalById, listenerHideModal } from "@/core/helpers/dom";
import { getModule } from 'vuex-module-decorators'
import {  Form } from "vee-validate";
import * as Validations from "yup";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import Select from "@/components/forms/Select.vue"
import SaleTypeModule from "@/store/modules/saleType/modules/new-saletype";
import OptionSelect from "@/models/shared/OptionSelect";

export default defineComponent({
    components:{
        DynamicContentModal,
        Form,
        Select
    },
    props:{
        idSale: {
            type: String
            , required: true
        }
    },
    emits: ['open-modal'],
    setup(props, {emit}) {
        //Propiedades
        const moduleNewSale = getModule(NewSaleModule);
        const moduleSalesType = getModule(SaleTypeModule);
        const formSentoToSale = ref();
        
        //Schema
        const validationSchema = Validations.object().shape({
              saleTypeId: Validations.string().nullable().default(null).required().label("saleTypeId"),
        });

        const submitForm = (data: any) => {

            moduleNewSale.SEN_TO_SALE({
              id: props.idSale,
              newSaleTypeId: data.saleTypeId
              // saleQuoteExpiredAt: '2023-02-27T14:41:11.801Z'
            });

            hideModalById("#SendToSaleModal");
        };

        const loadingSaleTypeCombo = computed(() => moduleSalesType.loading);
        const saleTypes = computed(() => moduleSalesType.getSaleTypeOptions);
        
        const resetForm = () => {
            formSentoToSale.value.resetForm();
        }

        watch(() => moduleSalesType.getSaleTypeOptions, async(currentValue: OptionSelect[]) => {//para usar el watch hay que hacerlo con base a un getter
          if(currentValue){

            const fristValue = currentValue[0];

            if(fristValue){
              formSentoToSale.value.setFieldValue('saleTypeId', fristValue.id);

            }
          }
        })

        return {
            validationSchema
            ,saleTypes
            ,formSentoToSale
            ,loadingSaleTypeCombo

            ,submitForm
            ,resetForm
        }

    },
    methods:{
        closeListener(){
            this.resetForm();
        },
        triggerHidden: function(){
            listenerHideModal("#SendToSaleModal", this.closeListener);
        },
    },
    mounted(){
        this.triggerHidden();
    }
})
